<template>
  <v-row justify="end" class="align-center">
    <v-combobox
      v-for="header in headers"
      :key="header.value"
      :items="fillFilter(header.value)"
      v-model="comboFilter[header.value]"
      clearable
      outlined
      class="mr-2 ml-2"
    >
      <template v-slot:label>
        {{ header.text }}
      </template>
    </v-combobox>
  </v-row>
</template>

<script>
import { inject } from "@vue/composition-api";

export default {
  setup() {
    const headers = inject("headers");
    const content = inject("content");
    const comboFilter = inject("comboFilter");

    const fillFilter = value => {
      return content.value.map(item => {
        if (value) {
          let splittedValue = value.split(".");
          return (
            (item &&
              (item[value] || item[splittedValue[0]]?.[splittedValue[1]])) ||
            ""
          );
        }
      });
    };

    return {
      comboFilter,
      fillFilter,
      headers,
      content,
    };
  },
};
</script>

<style lang="scss" scoped></style>
