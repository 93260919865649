import { ref } from "@vue/composition-api";

export function useValidators() {
  const required = val => !!val;
  const fullName = val =>
    /^[a-zA-Zа-яА-Я]+ [a-zA-Zа-яА-Я]+\s?[a-zA-Zа-яА-Я]*$/.test(val);
  const name = val => /^[a-zA-Zа-яА-Я]+$/.test(val);
  const minLength = num => val => val.length >= num;
  const maxLength = num => val => val.length <= num;
  const email = val =>
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      val
    );
  const phone = val => /^(\+\d{10,14}$)/.test(val) || /^\d{9,16}$/.test(val);

  return {
    required,
    fullName,
    name,
    minLength,
    maxLength,
    email,
    phone,
  };
}

export function useFieldValidation(field, value) {
  const isValid = ref(true);
  if (field.validators) {
    Object.values(field.validators).forEach(validator => {
      if (!validator(value)) isValid.value = false;
    });

    if (!field.validators["required"] && (!value || value == "-")) {
      isValid.value = true;
    }

    field.isValid = isValid.value;
  }
}

export function useFormValidation(headers) {
  let isValid = true;
  headers.forEach(header => {
    if (header.validators && !header.isValid) isValid = false;
  });

  return isValid;
}

export function compareFormData() {
  const checkObjectChanges = (current, initial) => {
    let changedRows = {};
    Object.keys(initial).forEach(key => {
      if (initial[key] != current[key]) {
        changedRows[key] = current[key];
      }
    });

    return Object.keys(changedRows).length ? changedRows : false;
  };

  const checkArrayChanges = (current, initial) => {
    let changedItems = [];

    initial.forEach(el => {
      if (!current.includes(el)) {
        changedItems.push(el);
      }
    });

    return changedItems.length ? changedItems : false;
  };

  return {
    checkObjectChanges,
    checkArrayChanges,
  };
}

export function formPagination() {
  const checkChanges = (currentData, initialData) => {
    let changedRows = {};
    Object.keys(initialData).forEach(key => {
      if (typeof currentData[key] === "object") {
        if (
          JSON.stringify(currentData[key]) != JSON.stringify(initialData[key])
        ) {
          changedRows[key] = currentData[key];
        }
      } else if (initialData[key] != currentData[key]) {
        changedRows[key] = currentData[key];
      }
    });
    return Object.keys(changedRows).length ? changedRows : false;
  };

  const getNextRow = (data, rows) => {
    const index = rows.indexOf(data);
    return index < rows.length - 1 ? rows[index + 1] : rows[0];
  };
  const getPrevRow = (data, rows) => {
    const index = rows.indexOf(data);
    return index > 0 ? rows[index - 1] : rows[rows.length - 1];
  };

  return {
    checkChanges,
    getNextRow,
    getPrevRow,
  };
}
