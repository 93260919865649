import { computed, reactive } from "@vue/composition-api";
import { useValidators } from "@/use/form";
import usePagination from "@/use/pagination";

const { getSelectMenu, scrollHandler } = usePagination();

export default function useTableHeaders(store) {
  const { required, email, phone, name } = useValidators();
  const profiles = computed(() => store.getters.getProfiles);
  const users = computed(() => store.getters.getAllShortUsers.content);

  const createUserTypes = computed(() => [
    {
      id: 0,
      name: "Новый пользователь",
    },
    {
      id: 1,
      name: "Зарегистрированный пользователь",
    },
  ]);

  const onChangeParticipantType = type => {
    const fullName = participantHeaders.find(el => el.value == "fullName");
    const firstName = participantHeaders.find(el => el.value == "firstName");
    const lastName = participantHeaders.find(el => el.value == "lastName");
    const middleName = participantHeaders.find(el => el.value == "middleName");

    if (type) {
      fullName.type = "combobox";
      fullName.fill = users;
      firstName.type = null;
      lastName.type = null;
      middleName.type = null;
    } else {
      fullName.type = null;
      fullName.fill = null;
      firstName.type = "text";
      lastName.type = "text";
      middleName.type = "text";
    }
  };

  const onChangeExpertType = type => {
    const fullName = expertHeaders.find(el => el.value == "fullName");
    const firstName = expertHeaders.find(el => el.value == "firstName");
    const lastName = expertHeaders.find(el => el.value == "lastName");
    const middleName = expertHeaders.find(el => el.value == "middleName");

    if (type) {
      fullName.type = "combobox";
      fullName.fill = users;
      firstName.type = null;
      lastName.type = null;
      middleName.type = null;
    } else {
      fullName.type = null;
      fullName.fill = null;
      firstName.type = "text";
      lastName.type = "text";
      middleName.type = "text";
    }
  };

  const onChangeAdminType = type => {
    const fullName = adminHeaders.find(el => el.value == "fullName");
    const firstName = adminHeaders.find(el => el.value == "firstName");
    const lastName = adminHeaders.find(el => el.value == "lastName");
    const middleName = adminHeaders.find(el => el.value == "middleName");

    if (type) {
      fullName.type = "combobox";
      fullName.fill = users;
      firstName.type = null;
      lastName.type = null;
      middleName.type = null;
    } else {
      fullName.type = null;
      fullName.fill = null;
      firstName.type = "text";
      lastName.type = "text";
      middleName.type = "text";
    }
  };

  const onChangeObserverType = type => {
    const fullName = observerHeaders.find(el => el.value == "fullName");
    const firstName = observerHeaders.find(el => el.value == "firstName");
    const lastName = observerHeaders.find(el => el.value == "lastName");
    const middleName = observerHeaders.find(el => el.value == "middleName");

    if (type) {
      fullName.type = "combobox";
      fullName.fill = users;
      firstName.type = null;
      lastName.type = null;
      middleName.type = null;
    } else {
      fullName.type = null;
      fullName.fill = null;
      firstName.type = "text";
      lastName.type = "text";
      middleName.type = "text";
    }
  };

  const onChangeExecutiveType = type => {
    const fullName = executiveHeaders.find(el => el.value == "fullName");
    const firstName = executiveHeaders.find(el => el.value == "firstName");
    const lastName = executiveHeaders.find(el => el.value == "lastName");
    const middleName = executiveHeaders.find(el => el.value == "middleName");

    if (type) {
      fullName.type = "combobox";
      fullName.fill = users;
      firstName.type = null;
      lastName.type = null;
      middleName.type = null;
    } else {
      fullName.type = null;
      fullName.fill = null;
      firstName.type = "text";
      lastName.type = "text";
      middleName.type = "text";
    }
  };

  const selectInfinityScroll = ({ refs, refName }) => {
    setTimeout(() => {
      const menu = getSelectMenu(refs[refName].$el);

      scrollHandler(menu, async () => {
        await store.dispatch("fetchAllShortUsers", {
          notRole: refName.toUpperCase(),
        });
      });
    }, 0);
  };

  const participantHeaders = reactive([
    {
      text: "Тип пользователя",
      type: "combobox",
      fill: createUserTypes,
      method: onChangeParticipantType,
      value: "createType",
      noTable: true,
      noEdit: true,
      validators: {},
    },
    {
      text: "ФИО",
      type: null,
      fill: null,
      method: async id => {
        if (store.getters.getCurrentUser.id == id || !id) return;

        await store.dispatch("fetchUserById", id);
      },
      filterable: true,
      value: "fullName",
      align: "center",
      width: "180px",
      validators: { required },
      ref: "participant",
      click: selectInfinityScroll,
    },
    {
      text: "Фамилия",
      value: "lastName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { required, name },
    },
    {
      text: "Имя",
      value: "firstName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { required, name },
    },
    {
      text: "Отчество",
      value: "middleName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { name },
    },
    {
      text: "Цель оценки",
      filterable: true,
      value: "localeAssessmentPurpose",
      align: "center",
    },
    {
      text: "Профиль оценки",
      filterable: true,
      value: "profileName",
      align: "center",
      type: "combobox",
      fill: profiles,
      validators: { required },
    },
    {
      text: "Подразделение",
      filterable: true,
      value: "currentDepartment",
      align: "center",
      type: "text",
      validators: { required },
      external: true,
    },
    {
      text: "Должность",
      filterable: true,
      value: "currentPosition",
      align: "center",
      type: "text",
      validators: { required },
      external: true,
    },
    {
      text: "Целевое подразделение",
      filterable: true,
      value: "goalDepartment",
      align: "center",
      type: "text",
      validators: { required },
      noDevelopment: true,
    },
    {
      text: "Целевая должность",
      filterable: true,
      value: "goalPosition",
      align: "center",
      type: "text",
      validators: { required },
      noDevelopment: true,
    },
    {
      text: "Телефон",
      filterable: true,
      value: "phone",
      align: "center",
      width: "150px",
      validators: { phone },
      type: "tel",
    },
    {
      text: "Почта (логин)",
      filterable: true,
      value: "email",
      align: "center",
      type: "email",
      validators: { required, email },
    },
    {
      text: "Администратор",
      filterable: true,
      value: "invitedBy",
      align: "center",
    },
    {
      text: "Дата регистрации",
      filterable: true,
      value: "formattedRegisterDate",
      align: "center",
    },
    {
      text: "Роли",
      filterable: true,
      value: "localeRoles",
      type: "list",
      model: "localeRoles",
      align: "center",
      noTable: true,
      editOnly: true,
    },
  ]);

  const adminHeaders = reactive([
    {
      text: "Тип пользователя",
      type: "combobox",
      fill: createUserTypes,
      method: onChangeAdminType,
      value: "createType",
      noTable: true,
      noEdit: true,
      validators: {},
    },
    {
      text: "ФИО",
      type: null,
      fill: null,
      method: async id => {
        if (store.getters.getCurrentUser.id == id || !id) return;

        await store.dispatch("fetchUserById", id);
      },
      filterable: true,
      value: "fullName",
      align: "center",
      width: "180px",
      validators: { required },
      ref: "admin",
      click: selectInfinityScroll,
    },
    {
      text: "Фамилия",
      value: "lastName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { required, name },
    },
    {
      text: "Имя",
      value: "firstName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { required, name },
    },
    {
      text: "Отчество",
      value: "middleName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { name },
    },
    {
      text: "Компания",
      filterable: true,
      value: "companyName",
      align: "center",
      type: "text",
      validators: { required },
      internal: true,
    },
    {
      text: "Подразделение",
      filterable: true,
      value: "currentDepartment",
      align: "center",
      type: "text",
      validators: { required },
      external: true,
    },
    {
      text: "Должность",
      filterable: true,
      value: "currentPosition",
      align: "center",
      type: "text",
      validators: { required },
      external: true,
    },
    {
      text: "Телефон",
      filterable: true,
      value: "phone",
      align: "center",
      width: "150px",
      type: "tel",
      validators: { phone },
    },
    {
      text: "Почта (логин)",
      filterable: true,
      value: "email",
      align: "center",
      type: "email",
      validators: { required, email },
    },
    {
      text: "Администратор",
      filterable: true,
      value: "invitedBy",
      align: "center",
    },
    {
      text: "Дата регистрации",
      filterable: true,
      value: "formattedRegisterDate",
      align: "center",
    },
    {
      text: "Роли",
      filterable: true,
      value: "localeRoles",
      type: "list",
      model: "localeRoles",
      align: "center",
      noTable: true,
      editOnly: true,
    },
  ]);

  const expertHeaders = reactive([
    {
      text: "Тип пользователя",
      type: "combobox",
      fill: createUserTypes,
      method: onChangeExpertType,
      value: "createType",
      noTable: true,
      noEdit: true,
      validators: {},
    },
    {
      text: "ФИО",
      type: null,
      fill: null,
      method: async id => {
        if (store.getters.getCurrentUser.id == id || !id) return;

        await store.dispatch("fetchUserById", id);
      },
      filterable: true,
      value: "fullName",
      align: "center",
      width: "180px",
      validators: { required },
      ref: "expert",
      click: selectInfinityScroll,
    },
    {
      text: "Фамилия",
      value: "lastName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { required, name },
    },
    {
      text: "Имя",
      value: "firstName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { required, name },
    },
    {
      text: "Отчество",
      value: "middleName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { name },
    },
    {
      text: "Компания",
      filterable: true,
      value: "companyName",
      align: "center",
      type: "text",
      validators: { required },
      internal: true,
    },
    {
      text: "Подразделение",
      filterable: true,
      value: "currentDepartment",
      align: "center",
      type: "text",
      validators: { required },
      external: true,
    },
    {
      text: "Должность",
      filterable: true,
      value: "currentPosition",
      align: "center",
      type: "text",
      validators: { required },
      external: true,
    },
    {
      text: "Телефон",
      filterable: true,
      value: "phone",
      align: "center",
      width: "150px",
      type: "tel",
      validators: { phone },
    },
    {
      text: "Почта (логин)",
      filterable: true,
      value: "email",
      align: "center",
      type: "email",
      validators: { required, email },
    },
    {
      text: "Администратор",
      filterable: true,
      value: "invitedBy",
      align: "center",
    },
    {
      text: "Дата регистрации",
      filterable: true,
      value: "formattedRegisterDate",
      align: "center",
    },
    {
      text: "Роли",
      filterable: true,
      value: "localeRoles",
      type: "list",
      model: "localeRoles",
      align: "center",
      noTable: true,
      editOnly: true,
    },
  ]);

  const observerHeaders = reactive([
    {
      text: "Тип пользователя",
      type: "combobox",
      fill: createUserTypes,
      method: onChangeObserverType,
      value: "createType",
      noTable: true,
      noEdit: true,
      validators: {},
    },
    {
      text: "ФИО",
      type: null,
      fill: null,
      method: async id => {
        if (store.getters.getCurrentUser.id == id || !id) return;

        await store.dispatch("fetchUserById", id);
      },
      filterable: true,
      value: "fullName",
      align: "center",
      width: "180px",
      validators: { required },
      ref: "observer",
      click: selectInfinityScroll,
    },
    {
      text: "Фамилия",
      value: "lastName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { required, name },
    },
    {
      text: "Имя",
      value: "firstName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { required, name },
    },
    {
      text: "Отчество",
      value: "middleName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { name },
    },
    {
      text: "Компания",
      filterable: true,
      value: "companyName",
      align: "center",
      type: "text",
      validators: { required },

      internal: true,
    },
    {
      text: "Подразделение",
      filterable: true,
      value: "currentDepartment",
      align: "center",
      type: "text",
      validators: { required },
      external: true,
    },
    {
      text: "Должность",
      filterable: true,
      value: "currentPosition",
      align: "center",
      type: "text",
      validators: { required },
      external: true,
    },
    {
      text: "Телефон",
      filterable: true,
      value: "phone",
      align: "center",
      width: "150px",
      type: "tel",
      validators: { phone },
    },
    {
      text: "Почта (логин)",
      filterable: true,
      value: "email",
      align: "center",
      type: "email",
      validators: { required, email },
    },
    {
      text: "Администратор",
      filterable: true,
      value: "invitedBy",
      align: "center",
    },
    {
      text: "Дата регистрации",
      filterable: true,
      value: "formattedRegisterDate",
      align: "center",
    },
    {
      text: "Роли",
      filterable: true,
      value: "localeRoles",
      type: "list",
      model: "localeRoles",
      align: "center",
      noTable: true,
      editOnly: true,
    },
  ]);

  const executiveHeaders = reactive([
    {
      text: "Тип пользователя",
      type: "combobox",
      fill: createUserTypes,
      method: onChangeExecutiveType,
      value: "createType",
      noTable: true,
      noEdit: true,
      validators: {},
    },
    {
      text: "ФИО",
      type: null,
      fill: null,
      method: async id => {
        if (store.getters.getCurrentUser.id == id || !id) return;

        await store.dispatch("fetchUserById", id);
      },
      filterable: true,
      value: "fullName",
      align: "center",
      width: "180px",
      validators: { required },
      ref: "leader",
      click: selectInfinityScroll,
    },
    {
      text: "Фамилия",
      value: "lastName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { required, name },
    },
    {
      text: "Имя",
      value: "firstName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { required, name },
    },
    {
      text: "Отчество",
      value: "middleName",
      align: "center",
      width: "180px",
      type: "text",
      noTable: true,
      validators: { name },
    },
    {
      text: "Подразделение",
      filterable: true,
      value: "currentDepartment",
      align: "center",
      type: "text",
      validators: { required },
    },
    {
      text: "Должность",
      filterable: true,
      value: "currentPosition",
      align: "center",
      type: "text",
      validators: { required },
    },
    {
      text: "Телефон",
      filterable: true,
      value: "phone",
      align: "center",
      width: "150px",
      type: "tel",
      validators: { phone },
    },
    {
      text: "Почта (логин)",
      filterable: true,
      value: "email",
      align: "center",
      type: "email",
      validators: { required, email },
    },
    {
      text: "Администратор",
      filterable: true,
      value: "invitedBy",
      align: "center",
    },
    {
      text: "Дата регистрации",
      filterable: true,
      value: "formattedRegisterDate",
      align: "center",
    },
    {
      text: "Роли",
      filterable: true,
      value: "localeRoles",
      type: "list",
      model: "localeRoles",
      align: "center",
      noTable: true,
      editOnly: true,
    },
  ]);

  const clientHeaders = reactive([
    {
      text: "Компания",
      filterable: true,
      value: "companyName",
      align: "center",
      width: "180px",
      type: "text",
      validators: { required },
    },
    {
      text: "Почта",
      filterable: true,
      value: "email",
      align: "center",
      type: "email",
      validators: { required, email },
    },
    {
      text: "Телефон",
      value: "phone",
      filterable: true,
      align: "center",
      type: "text",
      validators: { phone },
    },
    {
      text: "Адрес",
      value: "address",
      filterable: true,
      align: "center",
      type: "text",
      validators: {},
    },
    {
      text: "Видео",
      value: "isEnabledEmbeddedVideo",
      filterable: false,
      align: "center",
      type: null,
    },
  ]);

  const assessmentSessionHeaders = reactive([
    {
      text: "Статус",
      filterable: true,
      value: "status",
      align: "center",
    },
    {
      text: "Дата",
      filterable: true,
      value: "assessmentSessionDate",
      align: "center",
    },
    {
      text: "Время по МСК",
      width: "120px",
      filterable: true,
      value: "assessmentSessionTime",
      align: "center",
    },
    {
      text: "ФИО",
      filterable: true,
      width: "180px",
      value: "fullName",
      align: "center",
    },
    {
      text: "Цель оценки",
      filterable: true,
      value: "localeAssessmentPurpose",
      align: "center",
    },
    {
      text: "Профиль оценки",
      filterable: true,
      value: "participantUser.profileName",
      align: "center",
    },
    {
      text: "Подразделение",
      filterable: true,
      value: "participantUser.currentDepartment",
      align: "center",
      type: "text",
      validators: { required },
      external: true,
    },
    {
      text: "Должность",
      filterable: true,
      value: "participantUser.currentPosition",
      align: "center",
      type: "text",
      validators: { required },

      external: true,
    },
    {
      text: "Целевое подразделение",
      filterable: true,
      value: "participantUser.goalDepartment",
      align: "center",
      type: "text",
      validators: { required },
      promotion: true,
    },
    {
      text: "Целевая должность",
      filterable: true,
      value: "participantUser.goalPosition",
      align: "center",
      type: "text",
      validators: { required },
      promotion: true,
    },
    {
      text: "Телефон",
      filterable: true,
      value: "participantUser.phone",
      align: "center",
      width: "150px",
      validators: { phone },
      type: "tel",
    },
    {
      text: "Почта (логин)",
      filterable: true,
      value: "participantUser.email",
      align: "center",
      type: "email",
      validators: { required, email },
    },
    {
      text: "Администратор",
      filterable: true,
      value: "participantUser.invitedBy",
      align: "center",
    },
  ]);

  const expertSessionHeaders = reactive([
    {
      text: "",
      filterable: false,
      value: "actions",
      sortable: false,
      width: "40px",
    },
    {
      text: "Статус",
      filterable: true,
      value: "localeStatus",
      align: "center",
    },
    {
      text: "Дата",
      filterable: true,
      value: "assessmentSessionDate",
      align: "center",
    },
    {
      text: "Время по МСК",
      width: "120px",
      filterable: true,
      value: "assessmentSessionTime",
      align: "center",
    },
    {
      text: "ФИО",
      filterable: true,
      width: "180px",
      value: "fullName",
      align: "center",
    },
    {
      text: "Цель оценки",
      filterable: true,
      value: "localeAssessmentPurpose",
      align: "center",
    },
    {
      text: "Профиль оценки",
      filterable: true,
      value: "participantUser.profileName",
      align: "center",
    },
    {
      text: "Подразделение",
      filterable: true,
      value: "participantUser.currentDepartment",
      align: "center",
      type: "text",
      validators: { required },
      external: true,
    },
    {
      text: "Должность",
      filterable: true,
      value: "participantUser.currentPosition",
      align: "center",
      type: "text",
      validators: { required },

      external: true,
    },
    {
      text: "Целевое подразделение",
      filterable: true,
      value: "participantUser.goalDepartment",
      align: "center",
      type: "text",
      validators: { required },
      promotion: true,
    },
    {
      text: "Целевая должность",
      filterable: true,
      value: "participantUser.goalPosition",
      align: "center",
      type: "text",
      validators: { required },
      promotion: true,
    },
    {
      text: "Телефон",
      filterable: true,
      value: "participantUser.phone",
      align: "center",
      width: "150px",
      validators: { phone },
      type: "tel",
    },
    {
      text: "Почта (логин)",
      filterable: true,
      value: "participantUser.email",
      align: "center",
      type: "email",
      validators: { required, email },
    },
  ]);

  const defaultSessionHeaders = reactive([
    {
      text: "Статус",
      filterable: true,
      value: "localeStatus",
      align: "center",
    },
    {
      text: "Дата",
      filterable: true,
      value: "assessmentSessionDate",
      align: "center",
    },
    {
      text: "Время по МСК",
      width: "120px",
      filterable: true,
      value: "assessmentSessionTime",
      align: "center",
    },
    {
      text: "ФИО",
      filterable: true,
      width: "180px",
      value: "fullName",
      align: "center",
    },
    {
      text: "Цель оценки",
      filterable: true,
      value: "localeAssessmentPurpose",
      align: "center",
    },
    {
      text: "Профиль оценки",
      filterable: true,
      value: "participantUser.profileName",
      align: "center",
    },
    {
      text: "Подразделение",
      filterable: true,
      value: "participantUser.currentDepartment",
      align: "center",
      type: "text",
      validators: { required },
      external: true,
    },
    {
      text: "Должность",
      filterable: true,
      value: "participantUser.currentPosition",
      align: "center",
      type: "text",
      validators: { required },

      external: true,
    },
    {
      text: "Целевое подразделение",
      filterable: true,
      value: "participantUser.goalDepartment",
      align: "center",
      type: "text",
      validators: { required },
      promotion: true,
    },
    {
      text: "Целевая должность",
      filterable: true,
      value: "participantUser.goalPosition",
      align: "center",
      type: "text",
      validators: { required },
      promotion: true,
    },
  ]);

  const expertReportHeaders = reactive([
    {
      text: "",
      filterable: false,
      value: "actions",
      sortable: false,
      width: "40px",
    },
    {
      text: "Дата оценки",
      filterable: true,
      value: "assessmentSessionDate",
      align: "center",
      width: "124px",
    },
    {
      text: "Статус",
      filterable: true,
      value: "localeStatus",
      align: "center",
    },
    {
      text: "ФИО",
      filterable: true,
      value: "participantFullName",
      align: "center",
    },
    {
      text: "Цель оценки",
      filterable: true,
      value: "localeAssessmentPurpose",
      align: "center",
    },
    {
      text: "Профиль оценки",
      filterable: true,
      value: "profileName",
      align: "center",
    },
    {
      text: "Подразделение",
      filterable: true,
      value: "currentDepartment",
      align: "center",
    },
    {
      text: "Должность",
      filterable: true,
      value: "currentPosition",
      align: "center",
    },
    {
      text: "Целевое подразделение",
      filterable: true,
      value: "goalDepartment",
      align: "center",
    },
    {
      text: "Целевая должность",
      filterable: true,
      value: "goalPosition",
      align: "center",
    },
  ]);

  return {
    participantHeaders,
    adminHeaders,
    expertHeaders,
    observerHeaders,
    executiveHeaders,
    clientHeaders,
    assessmentSessionHeaders,
    expertSessionHeaders,
    defaultSessionHeaders,
    expertReportHeaders,
  };
}

export function customSort(items, sortBy, sortDesc) {
  if (!sortBy[0]) return items;
  sortBy = sortBy[0];
  sortDesc = sortDesc[0];
  if (sortBy.search(/date/i) !== -1) {
    const compare = (a, b) => (sortDesc ? a - b : b - a);
    items.sort((a, b) => {
      const aDateArr = a[sortBy].split(".");
      const bDateArr = b[sortBy].split(".");
      if (aDateArr[2] === bDateArr[2])
        if (aDateArr[1] === bDateArr[1])
          return compare(aDateArr[0], bDateArr[0]);
        else return compare(aDateArr[1], bDateArr[1]);
      else return compare(aDateArr[2], bDateArr[2]);
    });
  } else {
    const sortArr = sortBy.split(".");
    if (sortArr.length > 1)
      items.sort((a, b) => {
        if (!a[sortArr[0]][sortArr[1]]) return 1;
        if (!b[sortArr[0]][sortArr[1]]) return -1;

        if (sortDesc)
          return a[sortArr[0]][sortArr[1]]
            .toString()
            .localeCompare(b[sortArr[0]][sortArr[1]].toString());
        else
          return b[sortArr[0]][sortArr[1]]
            .toString()
            .localeCompare(a[sortArr[0]][sortArr[1]].toString());
      });
    else
      items.sort((a, b) => {
        if (!a[sortBy]) return 1;
        if (!b[sortBy]) return -1;

        if (sortDesc)
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        else return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
  }
  return items;
}
